:root {
  --mainDark:#343a40;
  --mainWhite:#fff;
  --mainOrange:rgb(240, 240, 83);
  --mainBlack:#000;

}
.welcome{
  background-color: rgb(79, 116, 218);
  height: 100px;
  text-align: center;
}
.text{
  padding-top: 25px;
  color: #ffffff;
}

.imagebox-header{
  margin-top: 20px;
}

.imagebox-image{
  margin-top: 60px;
}



a:link, a:visited, a:hover, a:active{
  text-decoration: none;
  color: var(--mainBlack);

}

.imagebox-desc{
  color: #74777a;
}
.mission-vission{
  background-color: #eef1f2;
  margin-top: 60px;
  padding-top: 100px;
  padding-bottom: 100px;
}
h1{
  color: #000408;
}
.p-text{
  margin-top: 40px;
  margin-right: 60px;
  width: 500px;
  margin: 0;
  text-align: justify;
  color: #74777a;

}



