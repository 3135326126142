.bangladesh-profile-title{
    padding-top: 55px;
    padding-bottom: 55px;
    background-color: #e5e5e5 ;
}

.bprofile-title{
    margin-top: 40px;
    /* margin-left: 200px; */

}

.bprofile-description{
    margin-top: 20px;
    /* margin-left: 200px;
    margin-right: 150px; */

}

.bprofile-main-port{
    margin-top: 40px;
    /* margin-left: 200px; */
    color: rgb(226, 15, 15);

}

.port-title{
    margin-top: 40px;
    /* margin-left: 230px; */
}

.prot-description{
    margin-top: 10px;
    /* margin-left: 230px;
    margin-right: 150px; */
    text-align: justify;
}

.port_sub_title{
    color: #74777a;
    margin-top: 5px;
    /* margin-left: 230px; */
    font-weight: 800;
}
.port_sub_title-with-des{
    margin-top: 5px;
    /* margin-left: 230px;
    margin-right: 150px; */
    text-align: justify;
    

}

.port_sub_title-with-des:before{
    color: #74777a;
    content: "Location: ";
    font-weight: 800;
   }

   .faciliti-point{
    margin-top: 0px;
    /* margin-left: 230px; */
   }
   .port_sub_sub_title{
    margin-top: 5px;
    /* margin-left: 250px;
    margin-right: 150px; */
    color: #72787d;
   }


