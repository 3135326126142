.about-title{
    padding-top: 55px;
    padding-bottom: 55px;
    background-color: #e5e5e5 ;
}

.about-infinity-title{
    margin-top: 40px;
    /* margin-left: 200px; */
}

.mission:before {
    color: rgb(11, 1, 1);
    content: "Our Mission: ";
    font-weight: bold;
   }

.vision:before {
    color: rgb(11, 1, 1);
    content: "Our Vision: ";
    font-weight: bold;
   }

.mission{
    margin-top: 40px;
    /* margin-left: 200px;
    margin-right: 200px; */
    padding-bottom: 10px;
    text-align: justify;
    color: #74777a;

}

 .vision{
    margin-top: 10px;
    /* margin-left: 200px;
    margin-right: 200px; */
    padding-bottom: 200px;
    text-align: justify;
    color: #74777a;

}

