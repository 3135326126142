.about-title{
    padding-top: 55px;
    padding-bottom: 55px;
    background-color: #e5e5e5 ;
}

.about-infinity-title{
    margin-top: 40px;
}

.description{
    margin-top: 40px;
    padding-bottom: 200px;
    text-align: justify;
    color: #74777a;

}