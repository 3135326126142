.top-footer{
   background-color:rgba(24,54,74,0.95);
   padding-top:  120px;
   padding-bottom: 120px;
   color: white;
 }
 .top-footer-text{
  margin-right: 150px;
 }
 .divider{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  width: 30;
  border-top: 8px solid #e2dcd8;
  margin-right: 520px;
 }

 .fa-icon{
  color: #f87b27;
  height: 50px;
  width: 50px;
 }

 .numb-count{
  font-size:xx-large;
 }

