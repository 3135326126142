.about-title{
    padding-top: 55px;
    padding-bottom: 55px;
    background-color: #e5e5e5 ;
}
@media screen and (min-width: 800px){
    .about-infinity-title{
        margin-top: 40px;
    }
}


.about-descrption{
    margin-top: 5px;
    padding-bottom: 5px;
    text-align: justify;
    color: #74777a;

}
.board-director-one{
    margin-top: 40px;
    padding-bottom: 5px;
    text-align: justify;
    color: #74777a;
}

.board-director-two{
    margin-top: 0px;
    padding-bottom: 200px;
    text-align: justify;
    color: #74777a;

}


 
