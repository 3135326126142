.about-title{
    padding-top: 55px;
    padding-bottom: 55px;
    background-color: #e5e5e5 ;
}

.about-infinity-title{
    margin-top: 40px;
    /* margin-left: 200px; */
}

.contact-us-title{
    margin-top: 40px;
    color: #74777a;

}

.contact-us-text{
    margin-top: 5px;
    color: #72787d;
   }

   .employ-amount:before {
    color: #74777a;
    content: "Total Employee: ";
    font-weight: bold;
   }


