.about-title{
    padding-top: 55px;
    padding-bottom: 55px;
    background-color: #e5e5e5 ;
}

.about-infinity-title{
    margin-top: 40px;
    /* margin-left: 200px; */
}

.title{
    margin-top: 20px;
    /* margin-left: 200px;
    margin-right: 200px; */
    text-align: justify;
    color: #74777a;

}


